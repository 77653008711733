import * as React from 'react'
import axios from 'axios'
import validator from 'validator'

import logo from '../images/logo.png'

import '../scss/password-reset.scss'

const PasswordResetPage = function() {
    const [email, setEmail] = React.useState('')
    const [sent, setSent] = React.useState(false)
    const [loading, setLoading] = React.useState(false)

    async function submit() {
        setLoading(true)

        if (email !== '') {
            if (validator.isEmail(email)) {
                try {
                    const response = await axios.post('https://reminder-api.bettershore-customers.com/requestresetpassword?username=' + email);

                    if (response.status === 200) {
                        setLoading(false)
                        setSent(true)
                    } else {
                        setLoading(false)
                        alert('Det skjedde en feil. Vennligst prøv igjen.')
                    }
                } catch (error) {
                    setLoading(false)
                    console.error(error);

                    alert('Det skjedde en feil. Vennligst prøv igjen.')
                }
            } else {
                setLoading(false)
                alert('Du må skrive inn en gyldig e-postadresse.')
            }
        } else {
            setLoading(false)
            alert('E-post må fylles ut.')
        }
    }

    return (
        <div className="password-reset">
            <div className="wrapper">
                <a href="/" className="logo">
                    <img src={logo} alt=""/>
                </a>

                <a href="/" className="frontpage-link">Til forsiden</a>

                {
                    !sent &&
                    <>
                        <h1 className="title">Nullstill ditt passord</h1>

                        <p className="intro">Skriv inn e-postadressen du bruker som ditt Reminder brukernavn, så sender vi deg en e-post med videre instruksjoner.</p>

                        <input disabled={loading} className="input" placeholder="Din e-postadresse.." type="text" onChange={(e) => { setEmail(e.target.value) }} />

                        <button onClick={() => { submit() }} disabled={loading} className="button">{loading ? 'Sender...' : 'Send'}</button>
                    </>
                }

                {
                    sent &&
                    <>
                        <h1 className="title">Nullstill ditt passord</h1>

                        <p className="intro">Takk! Vennligst sjekk din innboks.</p>
                    </>
                }

                <div className="submenu">
                    <a href="/password-reset">Glemt passord?</a>
                    <a href="/terms">Vilkår og personvern</a>
                </div>
            </div>
        </div>
    )
}

export default PasswordResetPage
